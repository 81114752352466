/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RiSendPlane2Line } from "react-icons/ri"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout className="contact-page" sx={contactStyles.contactPage}>
      <Helmet>
        <script src={withPrefix("functions.js")} type="text/javascript" />
      </Helmet>
      <SEO
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="grid-container wrapper">
        <h1>{frontmatter.title}</h1>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <form
          className="contact-form"
          action="/thanks"
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          id="form"
          enctype="multipart/form-data"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="" id="personalData">
            <p className="contact-form--introhead">
              <h3>Persönliche Angaben</h3>
              <span>* = Plichtfeld</span>
            </p>
            <p>
              <label>
                Vorname *
                <input placeholder="Max" type="text" name="Vorname" required />
              </label>
            </p>
            <p id="lastname">
              <label>
                Nachname *
                <input
                  placeholder="Mustermann"
                  type="text"
                  name="Nachname"
                  required
                />
              </label>
            </p>
            <p>
              <label id="lblcompany">
                Firma
                <input
                  placeholder="Musterfirma GmbH"
                  type="text"
                  name="Firma"
                  id="company"
                />
              </label>
              <div id="test">
                <p></p>
              </div>
            </p>
            <p>
              <label>
                E-Mail *
                <input
                  placeholder="max.mustermann@gmail.com"
                  type="email"
                  name="E-Mail"
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Telefon *
                <input
                  type="tel"
                  name="Telefon"
                  placeholder="0511 123 456"
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Geburtsdatum *
                <input
                  type="date"
                  name="Geburtsdatum"
                  placeholder="02.07.1972"
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Straße und Nr. *
                <input
                  placeholder="Musterstraße 23a"
                  type="street-address"
                  name="Straße und Nr."
                  required
                />
              </label>
            </p>
            <p className="grids col-1 sm-2 contact-form--place">
              <label>
                PLZ *
                <input placeholder="30900" type="number" name="PLZ" required />
              </label>
              <label>
                Ort*
                <input
                  placeholder="Musterstadt"
                  type="text"
                  name="Ort"
                  required
                />
              </label>
            </p>
          </div>
          <div className="contact-form--vehicledetails" id="vehicleDetails">
            <h3>Angaben zum Fahrzeug</h3>
            <p>
              <label>
                KFZ-Kennzeichen – falls vorhanden
                <input
                  type="text"
                  name="KFZ-Kennzeichen"
                  placeholder="H-AB-1234"
                />
              </label>
            </p>
            <p className="contact-form--radios">
              <h4>Kennzeichen</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Kennzeichen Art"
                      value="Schwarz"
                      id="licencePlate--1"
                    />
                    <span className="custom-radio"></span>
                    schwarzes Kennzeichen
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Kennzeichen Art"
                      value="Saison"
                      id="licencePlate--2"
                    />
                    <span className="custom-radio"></span>
                    Saison
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Kennzeichen Art"
                      value="ohne, weil keine Haftpflicht"
                      id="licencePlate--3"
                    />
                    <span className="custom-radio"></span>
                    ohne, weil keine Haftpflicht benötigt
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Kennzeichen Art"
                      value="ohne, weil noch nicht vorhanden"
                      id="licencePlate--4"
                    />
                    <span className="custom-radio"></span>
                    ohne, weil noch nicht vorhanden
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Kennzeichen Art"
                      value="rotes Kennzeichen"
                      id="licencePlate--5"
                    />
                    <span className="custom-radio"></span>
                    rotes Kennzeichen
                  </label>
                </span>
              </div>
            </p>
            <p className="contact-form--radios">
              <h4>Historisches Kennzeichen</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Historisches Kennzeichen"
                      value="Ja"
                      id="historicalMark--1"
                    />
                    <span className="custom-radio"></span>
                    Ja
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Historisches Kennzeichen"
                      value="Nein"
                      id="historicalMark--2"
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
              </div>
            </p>
            <p>
              <label>
                Hersteller *
                <input
                  type="text"
                  name="Hersteller"
                  placeholder="Porsche"
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Modell *
                <input type="text" name="Modell" placeholder="356" required />
              </label>
            </p>
            <p>
              <label>
                Erstzulassung *
                <input
                  type="date"
                  name="Erstzulassung"
                  placeholder="1951"
                  required
                />
              </label>
            </p>
            <p>
              <label>Jährliche Fahrleistung / km *</label>
              <select name="Jährliche Fahrleistung" id="distance-select">
                <option value="">Bitte auswählen...</option>
                <option value="1.000">1.000</option>
                <option value="2.000">2.000</option>
                <option value="3.000">3.000</option>
                <option value="4.000">4.000</option>
                <option value="5.000">5.000</option>
                <option value="6.000">6.000</option>
                <option value="7.000">7.000</option>
                <option value="8.000">8.000</option>
                <option value="9.000">9.000</option>
              </select>
            </p>
            <p>
              <label>
                Versicherungssumme (Wiederbeschaffungs- Marktwert/ €) *
              </label>
              <input
                type="number"
                name="Versicherungssumme"
                placeholder="150.000€"
                required
              />
            </p>
            <p>
              <label>Fahrzeugzustand *</label>
              <select name="Fahrzeugzustand" id="note-select" required>
                <option value="">Bitte auswählen...</option>
                <option value="Makeloser Zustand; Note 1">
                  Makeloser Zustand; Note 1
                </option>
                <option value="Sehr guter Zustand; Note 1-2">
                  Sehr guter Zustand; Note 1-2
                </option>
                <option value="Guter Zustand; Note 2">
                  Guter Zustand; Note 2
                </option>
                <option value="Befriedigender Zustand; Note 2-3">
                  Befriedigender Zustand; Note 2-3
                </option>
                <option value="Gebrauchter Zustand; Note 3">
                  Gebrauchter Zustand; Note 3
                </option>
                <option value="Ausreichender Zustand; Note 3-4">
                  Ausreichender Zustand; Note 3-4
                </option>
                <option value="Verbrauchter Zustand; Note 4">
                  Verbrauchter Zustand; Note 4
                </option>
                <option value="Mangelhafter Zustand; 4-5">
                  Mangelhafter Zustand; 4-5
                </option>
                <option value="Restaurierungsbedürftiger Zustand; Note 5">
                  Restaurierungsbedürftiger Zustand; Note 5
                </option>
              </select>
            </p>
            <p className="contact-form--radios">
              <h4>Haben Sie mehrere Oldtimer/Youngtimer? *</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Mehrere Oldtimer/Youngtimer"
                      value="Nein"
                      id="oldtimerOrYoungtimer--1"
                      required
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Mehrere Oldtimer/Youngtimer"
                      value="2"
                      id="oldtimerOrYoungtimer--2"
                    />
                    <span className="custom-radio"></span>2
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Mehrere Oldtimer/Youngtimer"
                      value="3-5"
                      id="oldtimerOrYoungtimer--3"
                    />
                    <span className="custom-radio"></span>
                    3-5
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Mehrere Oldtimer/Youngtimer"
                      value="mehr als 5"
                      id="oldtimerOrYoungtimer--4"
                    />
                    <span className="custom-radio"></span>
                    mehr als 5
                  </label>
                </span>
              </div>

              <div className="car-savings">
                <h5>Ab dem 2.Fahrzeug erhalten Sie ca. 50% Ersparnis.</h5>
              </div>
            </p>
            <p className="contact-form--radios">
              <h4>
                Befindet sich das KFZ weitgehend im Originalzustand ohne
                Umbauten (z.B. anderer Motor, Tieferlegung, Tuning etc.)? *
              </h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Originalzustand"
                      value="Ja"
                      id="originalStateYes"
                      required
                    />
                    <span className="custom-radio"></span>
                    Ja
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Originalzustand"
                      value="Nein"
                      id="originalStateNo"
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
              </div>
              <br />
              <label>
                Wenn nein, bitte erläutern
                <textarea
                  name="Originalzustand – Begründung"
                  id="originalStateTextarea"
                ></textarea>
              </label>
            </p>
            <p className="contact-form--radios">
              <h4>Gibt es ein Wertgutachten? *</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Wertgutachten"
                      value="Ja"
                      id="valuationReport--1"
                      required
                    />
                    <span className="custom-radio"></span>
                    Ja
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Wertgutachten"
                      value="Nein"
                      id="valuationReport--2"
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
              </div>
              <br />
              <label>
                <span>Wenn ja, Kurz-oder Vollgutachten</span>
                <select name="Kurz-oder Vollgutachten" id="reportSelect">
                  <option value="">Bitte auswählen...</option>
                  <option value="Kurzgutachten">Kurzgutachten</option>
                  <option value="Vollgutachten">Vollgutachten</option>
                </select>
              </label>
              <br />
              <br />
              <label>
                <span>Datum des Wertgutachten</span>
                <input
                  type="date"
                  name="Wertgutachten Datum"
                  placeholder="TT.MM.JJJJ"
                  id="dateReportSelect"
                />
              </label>
            </p>
            <p className="contact-form--radios">
              <h4>Steht ein Alltagsfahrzeug zur Verfügung? *</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Alltagsfahrzeug"
                      value="Ja"
                      id="dailyCarYes"
                      required
                    />
                    <span className="custom-radio"></span>
                    Ja
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Alltagsfahrzeug"
                      value="Nein"
                      id="dailyCarNo"
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
              </div>
              <br />
              <label>
                Wenn nein, Begründung
                <textarea
                  name="Kein Alltagsfahrzeug – Begründung"
                  id="txtDailyCar"
                />
              </label>
            </p>
            <div id="livingEnvironment">
              <p>
                <label>
                  Wohnumfeld *
                  <select
                    name="Wohnumfeld"
                    id="livingEnvironmentSelect"
                    required
                  >
                    <option value="" selected>
                      Bitte auswählen...
                    </option>
                    <option value="Einfamlilienhaus">Einfamlilienhaus</option>
                    <option value="Eigentumswohnung">Eigentumswohnung</option>
                    <option value="kein Wohneigentum">kein Wohneigentum</option>
                  </select>
                </label>
              </p>
              <p>
                <label>
                  Nutzer des KFZ *
                  <select
                    name="Nutzer des KFZ"
                    id="userOfVehicleSelect"
                    required
                  >
                    <option value="" selected>
                      Bitte auswählen...
                    </option>
                    <option value="Nur Sie Selbst">Nur Sie Selbst</option>
                    <option value="Sie und Partner">Sie und Partner</option>
                    <option value="Sie, Partner und Kinder">
                      Sie, Partner und Kinder
                    </option>
                  </select>
                </label>
              </p>
              <p>
                <label>
                  Geburtsdatum des jüngsten Fahrers *
                  <input
                    type="date"
                    name="Geburtsdatum jüngster Fahrer"
                    id="livingEnvironment_birthdate"
                    placeholder="TT.MM.JJJJ"
                  />
                </label>
              </p>
            </div>
          </div>
          <div className="contact-form--insurancedetails">
            <h3>Angaben zur Versicherung</h3>

            {/* <p className="contact-form--insurance-radios">
              <h4>Gewünschter Versicherungsumfang *</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Versicherungsumfang"
                      value="Allgefahrendeckung"
                      id="allRisksCover"
                    />
                    <span className="radio-buttons">Allgefahrendeckung</span>
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Versicherungsumfang"
                      value="Grunddeckung"
                      id="basicCover"
                    />
                    <span className="radio-buttons">Grunddeckung</span>
                  </label>
                </span>
              </div>
            </p> */}

            <p>
              <label>
                <h4>Gewünschter Versicherungsumfang *</h4>
              </label>
              <ul className="desiredDeductible desiredDeductible--insurancetype">
                <li id="liAllRisksCover">
                  <label>
                    <input
                      type="radio"
                      name="Versicherungsumfang"
                      id="allRisksCover"
                      value="Allgefahrendeckung"
                      required
                    />
                    <span>Allgefahrendeckung</span>
                  </label>
                </li>
                <li id="liBasicCover">
                  <label>
                    <input
                      type="radio"
                      name="Versicherungsumfang"
                      id="basicCover"
                      value="Grunddeckung"
                    />
                    <span>Grunddeckung</span>
                  </label>
                </li>
              </ul>
            </p>

            <p>
              <h4>Gewünschte Selbstbeteiligung *</h4>
              <div>
                <ul className="desiredDeductible">
                  <li id="lblbudget-1">
                    <label id="lblbudget-1">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-1"
                        value="150€"
                        required
                      />
                      <span>150€</span>
                    </label>
                  </li>
                  <li id="lblbudget-2">
                    <label id="lblbudget-2">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-2"
                        value="300€"
                      />
                      <span>300€</span>
                    </label>
                  </li>
                  <li id="lblbudget-3">
                    <label id="lblbudget-3">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-3"
                        value="500€"
                      />
                      <span>500€</span>
                    </label>
                  </li>
                  <li id="lblbudget-4">
                    <label id="lblbudget-4">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-4"
                        value="1000€"
                      />
                      <span>1000€</span>
                    </label>
                  </li>
                  <li id="lblbudget-5">
                    <label id="lblbudget-5">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-5"
                        value="2500€"
                      />
                      <span>2500€</span>
                    </label>
                  </li>
                  <li id="lblbudget-6">
                    <label id="lblbudget-6">
                      <input
                        type="radio"
                        name="Selbstbeteiligung"
                        id="budget-6"
                        value="5000€"
                      />
                      <span>5000€</span>
                    </label>
                  </li>
                </ul>
              </div>
            </p>
            <p className="contact-form--radios">
              <h4>Welche Zahlungsweise bevorzugen Sie? *</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Zahlungsweise"
                      value="jährlich"
                      id="paymentMethod--1"
                      required
                    />
                    <span className="custom-radio"></span>
                    jährlich
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Zahlungsweise"
                      value="halbjährlich"
                      id="paymentMethod--2"
                    />
                    <span className="custom-radio"></span>
                    halbjährlich
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Zahlungsweise"
                      value="monatlich"
                      id="paymentMethod--3"
                    />
                    <span className="custom-radio"></span>
                    monatlich
                  </label>
                </span>
              </div>
            </p>
            <p>
              <label>Zu wann benötigen Sie den Versicherungsschutz?</label>
              <input
                type="date"
                name="Versicherungsschutz Start"
                placeholder="TT.MM.JJJJ"
                required
              />
            </p>
            <p>
              <label>Abstellort des Fahrzeugs</label>
              <select name="Abstellort des Fahrzeugs" id="reportSelect">
                <option>Bitte auswählen...</option>
                <option value="Straße">Straße</option>
                <option value="eigenes Grundstück">eigenes Grundstück</option>
                <option value="Carport">Carport</option>
                <option value="Einzel-/Doppelgarage">
                  Einzel-/Doppelgarage
                </option>
                <option value="Tiefgarage (nicht-öffentlich)">
                  Tiefgarage (nicht-öffentlich)
                </option>
                <option value="Tiefgarage (öffentlich)">
                  Tiefgarage (öffentlich)
                </option>
                <option value="Tiefgarage (Parkbox)">
                  Tiefgarage (Parkbox)
                </option>
                <option value="Halle">Halle</option>
                <option value="Sammelgarage">Sammelgarage</option>
              </select>
            </p>
            <p className="contact-form--radios">
              <h4>Mitglied im Oldtimerclub?</h4>
              <div>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Oldtimerclub"
                      value="Ja"
                      id="oldtimerclub--1"
                      required
                    />
                    <span className="custom-radio"></span>
                    Ja
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      name="Oldtimerclub"
                      value="Nein"
                      id="oldtimerclub--2"
                    />
                    <span className="custom-radio"></span>
                    Nein
                  </label>
                </span>
              </div>
              <br />
              <label>Welcher Oldtimerclub?</label>
              <input type="text" />
            </p>
            <p>
              {/* <label>
                Datei hochladen (Wertgutachten, etc.) <br />
                <input type="file" name="Datei" />
              </label> */}
              <span>
                Wertgutachten oder ähnliches können später per Mail zugesandt
                werden.
              </span>
            </p>
            <p>
              <label>
                <input type="checkbox" name="Datenschutz" required />
                Ich erkläre mich mit der Verarbeitung der eingegebenen Daten
                sowie der <Link to="/datenschutz">
                  Datenschutzerklärung
                </Link>{" "}
                einverstanden.
              </label>
            </p>
          </div>
          <button className="button" type="submit">
            Kostenfreie Anfrage absenden
            <span className="icon -right">
              <RiSendPlane2Line />
            </span>
          </button>
        </form>
      </div>
    </Layout>
  )
}
export default Contact

const contactStyles = {
  contactPage: {
    /*input: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
    },
    textarea: {
      border: "6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground",
    },*/
  },
}
